.app__behandlingar-header_bg {
    width: 100%;

    background: url(../../../../assets/feets/IMG_4292.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
}

.app__behandlingar-header {
    display: flex;
    justify-content: center;
    width: 100%;

    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

    padding-top: 9rem;
}

.app__behandlingar-header-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;

    h1 {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        color: var(--white-gray-color);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 2.5rem;

        padding-left: 20px;

        transition: all 0.2s ease-in-out;

        p {
            padding: 5px 15px;
            font-size: 1.2rem;
            font-weight: 400;
            color: var(--light-primary-color);
        }
    }

    @media screen and (max-width: 1460px) {
        width: 96%;
    }

    @media screen and (max-width: 404px) {
        h1 {
            font-size: 1.8rem;
        }
        p {
            font-size: 1rem;
        }
    }
}

.app__behandlingar-header-content {
    position: relative;
    overflow: hidden;
    width: 100%;

    justify-content: center;
    align-items: center;

    background: var(--white-gray-color);

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top: 4px solid var(--medium-dark-primary-color);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 2;

    h1 {
        color: var(--medium-dark-primary-color);
        font-size: 1.5rem;
        padding-left: 0;
    
        @media screen and (max-width: 400px) {
            font-size: 1.2rem;
        }

        @media screen and (max-width: 307px) {
            font-size: 1.6rem;
        }
    }

    @media screen and (max-width: 360px) {
        p {
            font-size: 0.8rem !important;
        }
        h4 {
            font-size: 0.8rem;
        }
    }
}

.app__behandlingar-stora {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--light-primary-color);

    @media screen and (max-width: 1218px) {
        
        .app__behandlingar-stor {
            width: 50%;
        }

        .app__behandlingar-stor:last-child {
            width: 100%;
            border-top: 1px solid var(--light-primary-color);
        }
    }

    @media screen and (max-width: 812px) {
        .app__behandlingar-stor {
            width: 100%;
        }

        .app__behandlingar-stor:nth-child(2) {
            border-right: 0;
            border-left: 0;
            border-top: 1px solid var(--light-primary-color);
        }
    }

    @media screen and (max-width: 307px) {
        .app__behandlingar-stor {
            height: auto;
        }
    }
}

.app__behandlingar-stor {
    position: relative;
    overflow: hidden;
    width: 33.333%;
    min-width: 390px;
    height: 250px;

    padding: 2rem;

    color: black;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        padding: 2rem 0;
    }

    h4 {
        font-weight: 500;
    }

    .app__behandlingar-stor-banner {
        position: absolute;
        top: 24px;
        right: -50px;
    
        transform: rotate(45deg);
        font-size: 0.8rem;
        color: white;
        line-height: 1.2;
    
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--dark-primary-color);
        width: 200px;
        height: 54px;

        transition: all 0.2s ease-in-out;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 340px) {
            font-size: 0.65rem;
            height: 30px;
        }
    }

    .app__behandlingar-clickinfo {
        position: absolute;
        right: 0;
        bottom: 10px;
        opacity: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;
        color: var(--black-gray-color);
        font-size: 0.9rem;
        font-weight: 500;

        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        background: var(--white-gray-color);
        padding: 0.6rem 1.5rem;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

        transition: all 0.2s ease-in-out;
        transition-delay: 1s;

        transform: translateX(110%);

        svg {
            position: relative;
            width: 18px;
            height: 18px;
            opacity: 1;
            top: auto;
            left: auto;

            margin-left: 1rem;
        }

        @media screen and (max-width: 500px) {
            transform: translateX(0%);
            opacity: 1;
        }

        @media screen and (max-width: 340px) {
            font-size: 0.65rem;
            padding: 0.3rem 0.8rem;

            svg {
                height: 14px;
                width: 14px;
            }
        }
    }

    svg {
        position: absolute;
        width: 120%;
        height: 120%;
        top: -20%;
        left: -30%;
        opacity: 0.06;
    }

    &:hover {
        background: var(--medium-dark-primary-color);
        color: var(--white-gray-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        h1 {
            color: var(--white-gray-color);
        }

        .app__behandlingar-stor-banner {
            background: var(--white-gray-color);
            color: black;
        }

        .app__behandlingar-clickinfo {
            transform: translateX(0%);
            opacity: 1;
        }
    }

    @media screen and (max-width: 404px) {
        min-width: 100%;
    }
}

.app__behandlingar-stor:nth-child(2) {
    border-left: 1px solid var(--light-primary-color);
    border-right: 1px solid var(--light-primary-color);
}

.app__behandlingar-smaa {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--light-primary-color);
    text-decoration: none;

    @media screen and (max-width: 1624px) {
        .app__behandlingar-sma {
            width: 25%;
        }

        .app__behandlingar-sma:last-child {
            width: 100%;
            border-top: 1px solid var(--dark-primary-color);
        }
    }
    @media screen and (max-width: 1083px) {
        .app__behandlingar-sma {
            width: 33.333%;
        }

        .app__behandlingar-sma:last-child {
            width: 50%;
            border-top: 1px solid var(--dark-primary-color);
        }

        .app__behandlingar-sma:nth-child(4) {
            width: 50%;
            border-top: 1px solid var(--dark-primary-color);
        }
    }
    @media screen and (max-width: 812px) {
        .app__behandlingar-sma {
            width: 50%;
        }
        .app__behandlingar-sma:nth-child(3) {
            width: 50%;
            border-top: 1px solid var(--dark-primary-color);
        }

        .app__behandlingar-sma:last-child {
            width: 100%;
        }
    }

    @media screen and (max-width: 541px) {
        .app__behandlingar-sma {
            width: 100%;
            border-top: 1px solid var(--dark-primary-color);
            border-right: 0;
        }
        .app__behandlingar-sma:nth-child(3) {
            width: 100%;
        }
        .app__behandlingar-sma:nth-child(4) {
            width: 100%;
        }
    }
}

.app__behandlingar-sma {
    position: relative;
    overflow: hidden;
    width: 20%;
    min-width: 260px;
    height: 120px;

    padding: 2rem;
    text-decoration: none;
    color: var(--white-gray-color);
    background: var(--medium-dark-primary-color);

    transition: all 0.2s ease-in-out;
    cursor: pointer;

    border-right: 1px solid var(--dark-primary-color);

    h1 {
        color: var(--white-gray-color);
    }

    svg {
        position: absolute;
        width: 120%;
        height: 120%;
        top: -25%;
        left: -35%;
        opacity: 0.04;
    }

    &:hover {
        background: white;
        color: black;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        h1 {
            color: var(--medium-dark-primary-color);
        }
    }
}

.app__behandlingar-sma:last-child {
    border-right: 0;
}



.app__behandlingar-tillaggen {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;

    @media screen and (max-width: 541px) {
        .app__behandlingar-tillagg {
            width: 100%;
        }

        .app__behandlingar-tillagg:first-child {
            border-right: 0;
        }

        .app__behandlingar-tillagg:last-child {
            border-top: 1px solid var(--light-primary-color);
        }
    }
}

.app__behandlingar-tillagg {
    position: relative;
    overflow: hidden;
    width: 50%;
    min-width: 260px;
    height: 120px;


    padding: 2rem;

    text-decoration: none;
    color: black;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    svg {
        position: absolute;
        width: 160%;
        height: 160%;
        top: -45%;
        left: -65%;
        opacity: 0.05;
    }

    .app__behandlingar-tillagg_banner {
        position: absolute;
        top: 26px;
        right: -54px;
    
        transform: rotate(45deg);
        font-size: 0.8rem;
        color: white;
    
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--dark-primary-color);
        width: 200px;
        height: 40px;

        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    &:hover {
        background: var(--medium-dark-primary-color);
        color: var(--white-gray-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        h1 {
            color: var(--white-gray-color);
        }

        .app__behandlingar-tillagg_banner {
            background: var(--white-gray-color);
            color: black;
        }
    }
}

.app__behandlingar-tillagg:first-child {
    border-right: 1px solid var(--light-primary-color);
}




.app__behandlingsinfo-header {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 250px;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: var(--medium-dark-primary-color);

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);

    h1 {
        font-size: 2.7rem;
        font-weight: 400;
        color: var(--white-gray-color);
        z-index: 2;

        text-transform: uppercase;
    }

    svg {
        position: absolute;
        height: 150%;
        width: 150%;
        z-index: 1;
        opacity: 0.08;
    }

    @media screen and (max-width: 960px) {
        h1 {
            font-size: 1.3rem;
        }

        padding: 2rem;
    }
}