.app__tillag {
    width: 100%;
    padding: 6rem 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
        text-transform: uppercase;
        padding-left: 15px;
        font-size: 2.1rem;
        font-weight: 500;

        color: var(--dark-primary-color);

        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 1100px) {
        padding: 4rem 1rem;
    }
}

.app__tillag-container {
    display: flex;
    overflow: hidden;
    width: 80%;
    background: white;
    border-radius: 15px;

    border-top: 4px solid var(--medium-dark-primary-color);
    border-bottom: 4px solid var(--medium-dark-primary-color);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    div {
        width: 50%;
        height: 400px;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transition: all 0.3s ease-in-out;

        h1 {
            font-weight: 500;
            text-transform: uppercase;
        }

        p {
            font-size: 1rem;
            padding-bottom: 1rem;
            span {
                font-weight: 500;
                color: var(--dark-primary-color);

                transition: all 0.3s ease-in-out;
            }
        }

        &:hover {
            background: var(--medium-dark-primary-color);
            color: white;

            h1 {
                color: var(--white-gray-color);
            }

            p {
                span {
                    color: var(--light-primary-color);
                }
            }
        }
    }

    div:first-child {
        border-right: 1px solid var(--white-gray-color);

        @media screen and (max-width: 750px) {
            border-right: 0;
            border-bottom: 1px solid var(--white-gray-color);
        }
    }

    @media screen and (max-width: 1100px) {
        width: 96%;
    }

    @media screen and (max-width: 750px) {
        flex-direction: column;

        div {
            width: 100%;
        }
    }
}