.app__omoss {
    width: 100%;

    background: url(../../assets/feets/IMG_4283.jpg);
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    background-attachment: fixed;
}

.app__omoss-bg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--medium-dark-p-transparent-color);

    padding: 6rem 0;
}

.app__omoss-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;

    padding: 3rem 4rem;

    h1 {
        font-size: 1.8rem;
        font-weight: 500;

        color: var(--white-gray-color);
    }

    @media screen and (max-width: 900px) {
        padding: 1.5rem 2rem;
        width: 96%;
    }
}

.app__omoss-content {
    display: flex;
    width: 100%;

    @media screen and (max-width: 1050px) {
        flex-direction: column;
    }
}

.app__omoss-text {
    width: 100%;
    padding-right: 4rem;

    color: var(--white-gray-color);

    h2 {
        font-weight: 400;
        padding-left: 20px;
    }

    @media screen and (max-width: 1050px) {
        padding-right: 0;
    }
}

.app__omoss-img {
    position: relative;
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;

    border-radius: 50%;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    margin-bottom: 80px;

    .app__omoss-img2 {
        position: relative;
        width: 180px;
        height: 180px;
        border-radius: 50%;

        top: -120px;
        left: -60px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 1050px) {
            top: 0;
            left: 250px
        }
        @media screen and (max-width: 650px) {
            top: 280px;
            left: 100px;
        }
        @media screen and (max-width: 450px) {
            left: 120px;
            top: 340px;
        }

        @media screen and (max-width: 330px) {
            left: 80px;
            top: 350px;
        }
    }

    .app__omoss-img3 {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;

        bottom: -80px;
        left: -20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 1050px) {
            bottom: -40px;
            left: -50px;
        }
        
        @media screen and (max-width: 450px) {
            left: 0;
            bottom: -70px;
        }

        @media screen and (max-width: 330px) {
            bottom: -55px;
        }
    }

    @media screen and (max-width: 1050px) {
        left: calc(50% - 150px);
        margin-top: 2rem;
    }

    @media screen and (max-width: 650px) {
        margin-bottom: 150px;
    }
    @media screen and (max-width: 450px) {
        margin-bottom: 210px;
    }

    @media screen and (max-width: 330px) {
        width: 260px;
        min-width: 260px;
        height: 260px;
        min-height: 260px;

        left: calc(50% - 130px);
        margin-bottom: 250px;
    }
}