@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:wght@200;400;500;600&family=Open+Sans:wght@400;500;600;700;800&display=swap');

:root {
    --font-base: 'Open Sans', sans-serif;

    --white-color: #ffffff;
    --white-light-color: rgb(247, 247, 247);
    --white-gray-color: #f1f1f1;

    --light-primary-color: #bfd0d1;
    --medium-primary-color: #7da4a7;
    --medium-dark-primary-color: #2d7f83;
    --dark-primary-color: #006165;

    --medium-dark-p-transparent-color: #2d7f83dc;

    --black-gray2-color: #454545;
    --black-gray-color: #303030;
    --black-color: #000000;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}