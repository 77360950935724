.app__header {
    height: 100vh;
    width: 100%;
    z-index: 1;
}

.app__header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .app__header-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1080px;
        height: 560px;

        div {
            height: 3px;
            width: 98%;
            background: var(--medium-dark-primary-color);
            border-radius: 2px;

            margin: 1rem 0;

            @media screen and (max-width: 800px) {
                height: 2px;
            }
        }

        :nth-child(1) {
            width: 25%;
            margin-bottom: 0.7rem;

            @media screen and (max-width: 800px) {
                width: 30%;
            }

            @media screen and (max-width: 450px) {
                width: 40%;
            }
        }

        :nth-child(3) {
            width: 100%;
        }

        :nth-child(5) {
            width: 30%;
            @media screen and (max-width: 450px) {
                width: 40%;
            }
        }

        @media screen and (max-width: 1200px) {
            width: 90%;
            height: auto;
        }
    }
}