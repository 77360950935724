.app__presentkort {
    width: 100%;
    background: rgb(248, 248, 248);
    padding: 6rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1100px) {
        padding: 4rem 2rem;
    }

    @media screen and (max-width: 500px) {
        padding: 4rem 1.5rem;
    }

    h1 {
        font-size: 2.1rem;
        font-weight: 500;
        text-transform: uppercase;

        color: var(--dark-primary-color);

        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }
}

.app__presentkort-container {
    width: 80%;
    background: white;
    border-radius: 15px;
    border-top: 3px solid var(--medium-dark-primary-color);
    border-bottom: 3px solid var(--medium-dark-primary-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    display: flex;

    div {
        width: 50%;

        padding: 4rem 2rem;

        ul {
            padding-left: 2rem;
        }

        h2 {
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: uppercase;
            color: var(--medium-dark-primary-color);
        }

        p {
            font-size: 1rem;
            color: var(--black-gray-color);
        }
    }
    div:last-child {
        border-left: 1px solid var(--white-gray-color);
        background: var(--medium-dark-primary-color);

        h2 {
            color: white;
        }
        p {
            color: var(--white-gray-color);
        }
    }

    @media screen and (max-width: 1100px) {
        width: 100%;
    }

    @media screen and (max-width: 750px) {
        flex-direction:column-reverse;

        div {
            width: 100%;
        }
    }
}