.app__fsf {
    width: 100%;
    padding: 7rem 3rem;
    display: flex;
    justify-content: center;

    background: rgb(248, 248, 248);

    @media screen and (max-width: 1100px) {
        padding: 5rem 1rem;
    }
}

.app__fsf-container {
    display: flex;
    flex-direction: column;
    width: 80%;

    h1 {
        padding-left: 15px;
        font-size: 2.1rem;
        font-weight: 500;

        color: var(--dark-primary-color);

        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 1100px) {
        width: 96%;
    }

}


.app__fsf-top {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    background: white;
    border-radius: 15px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border-top: 4px solid var(--medium-dark-primary-color);

    .app__fsf-img {
        height: 600px;
        width: 50%;
        

        img {
            height: 600px;
            width: 100%;
            object-fit: cover;

            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
            height: 250px;

            img {
                height: 250px;

                border-bottom-right-radius: 0;
                border-top-left-radius: 15px;
            }
        }
    }

    .app__fsf-bok {
        width: 50%;
        padding: 2rem;

        h1 {
            font-size: 1.3rem;
            font-weight: 400;
            padding-left: 0;
        }

        p {
            font-size: 0.9rem;
            font-weight: 600;

            padding: 0.5rem 0;
            margin-bottom: 1rem;

            p {
                font-size: 0.8rem;
                padding: 0 0.5rem;
                font-weight: 400;
                margin-bottom: 0;
            }
        }

        h4 {
            font-size: 0.9rem;
            font-weight: 600;
            margin-top: 2rem;

            ul {
                li {
                    font-size: 0.8rem;
                    font-weight: 500;
                }
                @media screen and (max-width: 500px) {
                    padding: 1rem 1rem;
                }
            }
        }

        a {
            text-decoration: none;
            div {
                margin-bottom: 0.5rem;
                width: 100%;
                height: 50px;

                
                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 0.9rem;
                font-weight: 400;

                border: 0;
                outline: 0;

                background: var(--medium-dark-primary-color);
                color: var(--white-gray-color);
                border-radius: 6px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background: var(--medium-dark-p-transparent-color);
                }
            }

            @media screen and (max-width: 900px) {
                width: 100%;
            }
        }
        @media screen and (max-width: 900px) {
            width: 100%;
        }
        @media screen and (max-width: 500px) {
            padding: 1rem 0.5rem;
        }
    }

    .app__fsf-arrow {
        position: absolute;
        width: 101px;
        height: 101px;
        border-top: 50px solid var(--medium-dark-primary-color);
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 50px solid transparent;

        bottom: -101px;
        left: calc(50% - 50px);
    }


    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
}

.app__fsf-content {
    width: 100%;
    background: white;
    border-radius: 15px;
    margin-top: 1.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;

    border-bottom: 4px solid var(--medium-dark-primary-color);

    h1 {
        font-size: 1.5rem;
        font-weight: 500;
        padding-left: 0;

        @media screen and (max-width: 500px) {
            margin-top: 1.5rem;
        }
    }

    h2 {
        font-size: 1.2rem;
        font-weight: 500;
        padding-top: 2rem;
        color: var(--dark-primary-color);
    }

    p {
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        padding-bottom: 0.5rem;

        span {
            font-size: 0.9rem;
            color: var(--dark-primary-color);
        }
    }

    i {
        font-size: 0.9rem;
        color: var(--dark-gray-color);
    }

    @media screen and (max-width: 500px) {
        padding: 1rem 0.5rem;
    }
}