.mySwiper {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 0 !important;
}

.app__swiper-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    z-index: 0 !important;
}

.app__swiper-image {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;

    z-index: 0 !important;
}