.app__smapaket {
    padding: 6rem 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgb(248, 248, 248);

    @media screen and (max-width: 1100px) {
        padding: 4rem 1.5rem;
    }
}

.app__smapaket-container {
    width: 80%;

    h1 {
        padding-left: 15px;
        font-size: 2.1rem;
        font-weight: 500;
        text-transform: uppercase;

        color: var(--dark-primary-color);

        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 1100px) {
        width: 100%;
    }
}

.app__smapaket-content {
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 100%;
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;

    border-top: 4px solid var(--medium-dark-primary-color);
    border-bottom: 4px solid var(--medium-dark-primary-color);

    @media screen and (max-width: 850px) {
        flex-direction: column;
    }
}

.app__smapaket-item {
    position: relative;
    overflow: hidden;
    width: 50%;
    height: 160px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: var(--medium-dark-primary-color);
    color: var(--light-primary-color);
    transition: all 0.2s ease-in-out;

    &:hover {
        background: var(--white-gray-color);
        color: var(--black-gray-color);

        h1 {
            color: var(--medium-dark-primary-color);
        }
    }


    h1 {
        color: var(--white-gray-color);

        transition: all 0.2s ease-in-out;

        @media screen and (max-width: 400px) {
            font-size: 1.3rem;
        }
    }

    div {
        position: absolute;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 30%;

        background: rgba(255, 255, 255, 0.4);
        transform: translateY(-220%);
        transition: all 0.2s ease-in-out;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

        p {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 2;

            padding: 0 1rem;

            background: var(--white-gray-color);
            height: 100%;
            color: var(--dark-primary-color);

            font-weight: 500;

            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

            @media screen and (max-width: 370px) {
                padding: 0 0.4rem;
            }
            @media screen and (max-width: 350px) {
                display: none;
            }
        }

        a {
            width: 50%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            text-transform: uppercase;
            text-decoration: none;
            font-size: 0.9rem;

            border: 0;
            outline: 0;

            cursor: pointer;

            
            background: var(--medium-dark-p-transparent-color);
            color: var(--white-gray-color); 
            transition: all 0.2s ease-in-out;

            &:hover {
                background: rgba(255, 255, 255, 0.7);
                color: var(--dark-primary-color);
            }
            @media screen and (max-width: 500px) {
                font-size: 0.8rem;
            }
        }
        a:last-child {
            @media screen and (max-width: 500px) {
                justify-content: flex-end;
                padding-right: 1rem;
                border-left: 1px solid rgba(0, 0, 0, 0.3);
            }
        }

    }

    &:hover {

        div {
            transform: translateY(-117%);
            transition-delay: 0.3s;
        }
    }
    @media screen and (max-width: 850px) {
        width: 100%;
    }
}

.app__smapaket-item:first-child {
    border-right: 1px solid var(--dark-primary-color);
}

.app__smapaket-item:last-child {
    width: 100%;
    border-top: 1px solid var(--dark-primary-color);
}

.app__smapaket-item:nth-child(3) {
    border-top: 1px solid var(--dark-primary-color);
    border-right: 1px solid var(--dark-primary-color);
}

.app__smapaket-item:nth-child(4) {
    border-top: 1px solid var(--dark-primary-color);
}