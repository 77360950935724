.app {
    font-family: var(--font-base);
    background-color: var(--white-gray-color);
}

.app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__hr {
    width: 35px;
    height: 6px;
    background: linear-gradient(0.25turn, var(--medium-dark-primary-color), var(--dark-primary-color));
    border-radius: 3px;
    margin: 1.2rem 0;
}

.app__hr-light {
    width: 35px;
    height: 6px;
    background: linear-gradient(0.25turn, var(--white-gray-color), var(--light-primary-color));
    border-radius: 3px;
    margin: 1.2rem 0;
}