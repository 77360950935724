* {
    font-family: var(--font-base);
}

.app__footer {
    background: var(--dark-primary-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: hidden;

    padding-top: 4rem;

    h1 {
        color: var(--white-gray-color);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.8rem;
    }

    .app__footer-hr {
        width: 60%;
        height: 2px;
        background: linear-gradient(0.25turn, var(--medium-dark-primary-color), var(--medium-primary-color));
        border-radius: 2px;
        margin: 2rem 0;

        opacity: 0.7;
    }
}

.app__footer-container {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 2rem 0;

    .app__footer-links {
        position: relative;
        display: flex;

        gap: 5px;

        width: 32%;
        height: auto;

        background:rgba(255, 255, 255, 0.03);
        border-radius: 15px;

        .app__footer-link {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            padding: 1rem;

            @media screen and (max-width: 310px) {
                padding: 1rem 0.6rem;
            }
            a {
                padding: 10px 20px;
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                border-radius: 10px;
                margin-right: 5px;

                color: var(--white-gray-color);
                text-decoration: none;
                text-transform: uppercase;
                font-size: 0.9rem;

                transition: all 0.2s ease-in-out;

                &:hover {
                    background:rgba(255, 255, 255, 0.05);
                    color: white;
                    padding-left: 25px;
                    margin-right: 0;
                }
                @media screen and (max-width: 340px) {
                    padding: 10px 0;
                }
            }
        }

        .app__footer-dropdownlink {
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            background:rgba(255, 255, 255, 0.03);

            padding: 1rem;
            border-radius: 15px;

            .app__footer-arrow {
                position: absolute;
                height: 31px;
                width: 31px;
                border-left: 15px solid transparent;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right: 15px solid rgba(255, 255, 255, 0.03);
    
                top: 58px;
                left: -31px;
            }

            a {
                color: var(--light-primary-color);
                text-decoration: none;
                font-weight: 300;

                padding: 5px 10px;
                border-radius: 4px;

                font-size: 0.8rem;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                    color: white;
                    padding-left: 13px;
                }
            }
        }
        @media screen and (max-width: 1220px) {
            width: 39%;
        }
        @media screen and (max-width: 1000px) {
            min-width: 320px;
            width: 49%;
        }
        @media screen and (max-width: 800px) {
            min-width: 410px;
            width: 100%;
            margin-bottom: 1rem;
        }
        @media screen and (max-width: 500px) {
            min-width: 260px;
        }
    }

    .app__footer-sponsor {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 39%;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 15px;
        overflow: hidden;
        padding: 1rem;

        h1 {
            font-size: 1.1rem;
            font-weight: 500;
            color: var(--light-primary-color);
            padding-bottom: 1rem;
        }

        div {
            display: flex;
            width: 100%;
            gap: 10px;
            flex-wrap: wrap;

            img {
                max-height: 100px;
                max-width: 150px;
                border-radius: 6px;
                object-fit: scale-down;

                filter: grayscale(100%);
                opacity: 0.65;
                cursor: pointer;

                transition: all 0.3s ease-in-out;

                &:hover {
                    filter: grayscale(0) drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
                    opacity: 1;
                }
            }
        }
        
        @media screen and (max-width: 1220px) {
            width: 32%;
            flex-wrap: wrap;
        }
        @media screen and (max-width: 1000px) {
            min-width: 320px;
            width: 49%;
        }
        @media screen and (max-width: 800px) {
            min-width: 410px;
            width: 100%;
        }
        @media screen and (max-width: 500px) {
            min-width: 260px;
        }
    }

    .app__footer-content {
        position: relative;
        display: flex;
        width: 25%;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 15px;
        overflow: hidden;

        .app__footer-logo {
            position: absolute;
            right: -20%;
            bottom: - 25%;

            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            background: transparent;
            border-radius: 15px;
            z-index: 0;

            img {
                width: 70%;
                opacity: 0.6;
            }
        }

        .app__footer-contactinfo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            padding: 1rem;
            z-index: 2;

            h1 {
                font-size: 1.1rem;
                font-weight: 500;
                color: var(--light-primary-color);
            }

            h2 {
                font-size: 1rem;
                font-weight: 400;
                color: var(--medium-primary-color);
                margin-top: 1rem;
            }

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: var(--white-gray-color);
            }
        }
        @media screen and (max-width: 1000px) {
            min-width: 320px;
            margin-top: 1rem;
            width: 100%;
        }
        @media screen and (max-width: 500px) {
            min-width: 260px;
        }
    }

    @media screen and (max-width: 1400px) {
        width: 96%;
    }

    @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
    }
}

.app__footer-social {
    display: flex;
    gap: 5px;

    svg {
        transform: scale(0.9);
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1);
        }
    }
}

.app__footer-copyright {
    color: var(--medium-primary-color);
    text-transform: uppercase;
    margin: 1rem 0;
    font-size: 0.7rem;
}