.app__indextext {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-light-color);

    padding: 6rem 0;
}

.app__indextext-container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;

    overflow: hidden;

    background: var(--white-color);

    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    .app__indextext-textcontainer {
        padding: 3rem 4rem;

        h1 {
            font-weight: 400;
            font-size: 2rem;

            color: var(--dark-primary-color);
        }

        p {
            color: var(--black-gray-color);

            font-size: 1rem;
            line-height: 1.5;
        }

        a {
            color: var(--dark-primary-color);
            text-decoration: none;

            transition: all 0.2s ease-in-out;
            svg {
                position: relative;
                width: 20px;
                height: 20px;

                top: 4px;
            }

            &:hover {
                color: var(--medium-dark-primary-color);

                padding-left: 5px;

            }
        }

        @media screen and (max-width: 900px) {
            padding: 1.5rem 2rem;
        }
    }

    img {
        position: relative;
        right: 0;
        height: 100%;
        max-height: 450px;

        @media screen and (max-width: 1350px) {
            border-bottom-right-radius: 20px;
        }

        @media screen and (max-width: 900px) {
            height: auto;
            max-height: auto;
            width: 100%;
            border-bottom-left-radius: 0;

            object-fit: cover;
        }
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;

        width: 90%;
    }

    @media screen and (max-width: 450px) {
        width: 92%;
    }
}