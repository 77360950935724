.app__kontakt {
    background: var(--white-light-color);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 6rem 0;
}

.app__kontakt-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;

    padding: 2rem 3rem;

    h1 {
        font-size: 1.8rem;
        font-weight: 500;

        color: var(--dark-primary-color);
    }

    h2 {
        color: var(--dark-primary-color);
        font-size: 1.5rem;
        font-weight: 400;
        margin-top: 2rem;
    }

    @media screen and (max-width: 1100px) {
        padding: 1.5rem 1rem;
        width: 96%;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
    }
}

.app__kontakt-form_container {
    position: relative;
    width: 100%;
    display: flex;

    background: #f8f8f8;

    border-radius: 15px;

    border-right: 3px solid var(--medium-dark-primary-color);

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    .app__kontakt-form-text {
        width: 40%;
        background: var(--medium-dark-primary-color);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-radius: 15px;

        h1 {
            color: var(--white-gray-color);
            font-size: 1.8rem;
            font-weight: 400;

            text-transform: uppercase;

            padding: 3rem;
        }

    }

    .app__kontakt-form {
        width: 60%;
        display: flex;
        flex-direction: column;
        padding: 2rem;


        .app__kontakt-form_input {
            width: 100%;
            display: flex;
            
            .app__kontakt-form_icon {
                position: absolute;
                padding: 0.74rem 0.5rem;
                overflow: hidden;

                background: var(--medium-dark-primary-color);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;


                svg {
                    color: white;
                    opacity: 0.8;
                    width: 13px;
                    height: 13px;
                }
            }

            input {
                width: 100%;
                padding: 0.8rem 1rem;
                padding-left: 2.7rem;
                margin-bottom: 1rem;

                border: 0;
                border-bottom: 2px solid var(--medium-dark-primary-color);
                outline: 0;
                border-radius: 4px;

                transition: all 0.3s ease-in-out;

                &::placeholder {
                    color: var(--medium-dark-primary-color);
                    opacity: 0.7;
                }
            }

            &:hover {
                input {
                    box-shadow: 0 0 5px var(--light-primary-color);
                }
            }

            input:not(:placeholder-shown) {
                border-bottom: 2px solid green;
            }
        }

        .app__kontakt-form_textarea {
            width: 100%;
            display: flex;

            .app__kontakt-form_icon {
                position: absolute;
                padding: 0.74rem 0.5rem;
                overflow: hidden;

                height: 150px;

                background: var(--medium-dark-primary-color);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                svg {
                    color: white;
                    opacity: 0.8;
                    width: 13px;
                    height: 13px;
                }
            }

            textarea {
                width: 100%;
                padding: 0.8rem 1rem;
                padding-left: 2.2rem;
                margin-bottom: 1rem;
                resize: none;
                height: 150px;

                border: 0;
                border-bottom: 2px solid var(--medium-dark-primary-color);
                outline: 0;
                border-radius: 4px;
                transition: all 0.3s ease-in-out;

                &::placeholder {
                    color: var(--medium-dark-primary-color);
                    opacity: 0.7;
                }
            }

            &:hover {
                textarea {
                    box-shadow: 0 0 5px var(--light-primary-color);
                }
            }

            textarea:not(:placeholder-shown) {
                border-bottom: 2px solid green;

                textarea {
                    box-shadow: 0 0 5px var(--light-primary-color);
                }
            }
        }

        @media screen and (max-width: 450px) {
            padding: 2rem 0.5rem;

            .spinner-div_contact-form {
                width: calc(100% - 1rem);
            }
        }

        @media screen and (max-width: 850px) {
            .spinner-div_contact-form {
                width: calc(100% - 4rem);
                height: calc(100% - 40%);
            }
        }
    }

    .app__contact-form_button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
        
        p {
            font-size: 0.9rem;
            font-weight: 500;
            color: red;
        }
        h3 {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1rem;
            font-weight: 500;
            color: var(--dark-primary-color);
            transition: all 0.2s ease-in-out;
            padding-left: 1.5rem;

            transform: translateY(-100%);
            opacity: 0;

            svg {
                height: 120px;
                width: 120px;
                position: absolute;
                top: -120px;
                left: -20px;
            }

            &.active {
                transform: translateY(0%);
                opacity: 1;
            }

            #contact-form_error {
                transition: all 0.2s ease-in-out;
            }
        }

        button {
            width: 150px;
            padding: 0.5rem 1rem;
            font-size: 0.9rem;

            border: 2px solid var(--medium-dark-primary-color);
            border-radius: 5px;
            background: transparent;
            color: var(--dark-primary-color);

            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                background: var(--medium-dark-primary-color);
                color: var(--white-gray-color);
            }
        }
    }

    @media screen and (max-width: 850px) {
        flex-direction: column;

            border-right: 0px;
            border-bottom: 3px solid var(--medium-dark-primary-color);

        .app__kontakt-form-text {
            width: 100%;

            h1 {
                font-size: 1.3rem;
            }
        }

        .app__kontakt-form {
            width: 100%;
        }
    }
}

.app__kontakt-ways {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    h3 {
        font-weight: 500;
    }

    p {
        font-size: 0.9rem;
    }

    .app__kontakt-visit-red {
        color: var(--medium-dark-primary-color);
    }

    .app__kontakt-email {
        position: relative;
        overflow: hidden;
        width: 32%;
        min-width: 300px;
        height: 200px;
        background: white;
        border-radius: 10px;
        padding: 1.5rem;

        border-top: 1px solid var(--light-primary-color);
        border-bottom: 2px solid var(--medium-dark-primary-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

        transition: all 0.3s ease-in-out;

        svg {
            position: absolute;
            width: 180px;
            height: 180px;

            top: -30px;
            right: -30px;
            z-index: 0;
            opacity: 0.07;
        }

        &:hover {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.17);
        }
        @media screen and (max-width: 1300px) {
            width: 49%;
        }
        @media screen and (max-width: 760px) {
            width: 100%;
            min-width: auto;
        }
    }

    .app__kontakt-phone {
        position: relative;
        overflow: hidden;
        width: 32%;
        min-width: 300px;
        height: 200px;
        background: white;
        border-radius: 10px;
        padding: 1.5rem;

        border-top: 1px solid var(--light-primary-color);
        border-bottom: 2px solid var(--medium-dark-primary-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

        transition: all 0.3s ease-in-out;

        svg {
            position: absolute;
            width: 180px;
            height: 180px;

            top: -30px;
            right: -30px;
            z-index: 0;
            opacity: 0.07;
        }

        &:hover {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.17);
        }
        @media screen and (max-width: 1300px) {
            width: 49%;
        }
        @media screen and (max-width: 760px) {
            width: 100%;
            margin-top: 1rem;
            min-width: auto;
        }
    }

    .app__kontakt-visit {
        position: relative;
        overflow: hidden;
        width: 32%;
        min-width: 300px;
        height: 200px;
        background: white;
        border-radius: 10px;
        padding: 1.5rem;

        border-top: 1px solid var(--light-primary-color);
        border-bottom: 2px solid var(--medium-dark-primary-color);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

        transition: all 0.3s ease-in-out;

        svg {
            position: absolute;
            width: 180px;
            height: 180px;

            top: -30px;
            right: -30px;
            z-index: 0;
            opacity: 0.07;
        }

        &:hover {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.17);
        }

        @media screen and (max-width: 1300px) {
            width: 100%;
            margin-top: 1rem;
            min-width: auto;
        }
    }

}

.spinner-div_contact-form {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40% - 4rem);
    height: calc(100% - 4rem);
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    transform: scale(0) translateY(0) !important;
    opacity: 0 !important;

    &.active {
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    }
    .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
    }
    .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: var(--medium-dark-primary-color);
    }
    .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
    }
    .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
    }
    .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
    }
    .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
    }
    .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
    }
    .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
    }
    .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
    }
    .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
    }
    .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
    }
    .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
    }
    .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
    }
    .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    }
    @keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}