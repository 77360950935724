.app__google-map {
    position: relative;

    span {
        position: absolute;
        width: 50px;
        height: 50px;

        top: 1rem;
        left: 1rem;
        z-index: 10;
        margin: 0;
        padding: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }
}