.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem 2rem;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.app__navbar-logo {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    img {
        height: 50px;
    
        @media screen and (max-width: 500px) {
            height: auto;
            width: 200px;
        }

        @media screen and (max-width: 330px) {
            width: 160px;
        }
    }
}

.app__navbar-contact {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0;
    padding: 0;

    opacity: 0;
    pointer-events: none;

    @media screen and (max-width: 990px) {
        display: none;
    }
}

.dropdown {
    position: absolute;
    display: none;
    justify-content: center;
    width: 100%;
    top: 67px;
    left: 0;
    right: 0;

    background: var(--medium-dark-primary-color);

    border-bottom: 3px solid var(--medium-dark-primary-color);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

    padding: 1rem;

    .dropdown-arrow {
        position: absolute;
        left: calc(50% - 100px);
        top: -31px;
        width: 31px;
        height: 31px;
        background-color: transparent;

        border-top: 15px solid transparent;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid var(--medium-dark-primary-color);

        pointer-events: none;
    }

    .dropdown-container {
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media screen and (max-width: 1500px) {
            width: 65%;
        }

        @media screen and (max-width: 1150px) {
            width: 80%;
        }

        @media screen and (max-width: 900px) {
            width: 94%;
        }
    }

    .dropdown-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: var(--medium-dark-primary-color);

        position: absolute;
        bottom: -20px;


        svg {
            fill: var(--light-primary-color);
            height: 80%;
            width: 80%;
            transition: all 0.2s ease-in-out;

            &:hover {
                fill: white;
                transform: scale(1.05);
            }
        }
    }
}


.active {
    -webkit-animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    display: flex;
}

@-webkit-keyframes slide-in-top {
    0% {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
    opacity: 0;
}
100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
}
@keyframes slide-in-top {
0% {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
    opacity: 0;
}
100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
}





.app__navbar-links {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;

    margin-top: 0.4rem;

    li {
        margin: 0 0.7rem;
        cursor: pointer;

        flex-direction: column;

        .app__navbar-item-underline {
            width: 100%;
            height: 3px;
            border-radius: 2px;

            margin-top: 1px;

            transform: scale(0%);
            transition: all 0.2s ease-in-out;
            transition-delay: 0.2s;
        }

        .app__navbar-link {
            padding: 0 1.5rem;
            color: var(--black-gray-color);
            text-decoration: none;
            text-transform: uppercase;
            font-size: 0.9rem;

            flex-direction: column;
            font-weight: 510;
            transition: all 0.2s ease-in-out;
            font-family: var(--font-base);
            transition-delay: 0.2s;
        }

        &:hover {
            .app__navbar-item-underline {
                background-color: var(--medium-dark-primary-color);
                transform: scale(100%);
                transition-delay: 0s;
            }

            .app__navbar-link {
                color: var(--black-gray2-color);
                transition-delay: 0s;
            }
        }

        @media screen and (max-width: 1220px) {
            display: none;
        }
    }
}


.app__navbar-item-dropdown {
    height: 50px;
    display: flex;
    align-items: center;
    transition: all 0.4s ease;
    padding: 0.5rem;
    border-radius: 10px;

    text-decoration: none;
    text-transform: uppercase;
    font-family: var(--font-base);
    color: var(--white-gray-color);
    font-size: 0.9rem;
    font-weight: 500;

    span {
        position: relative;
        top: 2.5px;
        left: -20px;
        opacity: 0;
        transition: all 0.4s ease-in-out;

        svg {
            fill: var(--light-primary-color);
            opacity: 0.6;
        }
    }

    &:hover {
        background: rgba(255, 255, 255, 0.1);
        padding-left: 15px;

        span {
            left: 0;
            opacity: 1;
        }
    }
}


.app__navbar-mobile {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 1rem;

    background: var(--medium-dark-primary-color);
    cursor: pointer;

    svg {
        width: 80%;
        height: 80%;
        color: white;
    }

        div {
                position: fixed;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1000;

                padding: 1rem;

                width: 80%;
                height: 100vh;

                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;

                background: rgba(255, 255, 255, 0.9);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);

                box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

                -webkit-animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	                    animation: slide-in-right 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                svg {
                        width: 35px;
                        height: 35px;
                        color: var(--dark-primary-color);
                        margin: 0.5rem 1rem;
                        cursor: pointer;
                }

                ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        width: 100%;

                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-direction: column;

                        li {
                                padding: 1rem;
                                width: 100%;
                                -webkit-animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	                                    animation: slide-in-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                                a {
                                        width: 100%;
                                        color: var(--medium-dark-primary-color);
                                        text-decoration: none;
                                        font-size: 1rem;
                                        text-transform: uppercase;
                                        font-weight: 500;

                                        transition: all 0.3s ease-in-out;

                                        &:hover {
                                                color: var(--secondary-color);
                                        }
                                        &:active {
                                                color: var(--secondary-color);
                                        }
                                }

                                svg {
                                    height: 12px;
                                    width: 12px;

                                    margin: 0;
                                }

                                li {
                                    -webkit-animation: slide-in-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	                                        animation: slide-in-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                                    padding: 0.5rem 0;
                                    padding-left: 20px;

                                    a {
                                        color: var(--dark-primary-color);
                                    }
                                }
                        }
                }

                @media screen and (min-width: 1220px) {
                        display: none;
                }
        }
        @media screen and (min-width: 1220px) {
                display: none;
        }
}

@-webkit-keyframes slide-in-right {
    0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
}
100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}
}
@keyframes slide-in-right {
0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
}
100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
}
}